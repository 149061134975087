import * as React from "react";
import { Button, Divider, Grid, Header, Image, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";
import car from "../images/car.jpg";
import { Link } from "gatsby";

const ContactPage = () => {
  return (
    <Container>
      <Segment style={{ padding: "4em 0em" }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h3" style={{ fontSize: "2em" }}>
                Contact Us
              </Header>
              <h3>Address</h3>
              <p style={{ fontSize: "1.33em" }}>
                Hurst-Weiss Insurance <br />
                <a style={{color: "inherit"}} href="https://www.google.com/maps/place/1653+McFarland+Rd,+Pittsburgh,+PA+15216/@40.394406,-80.046807,13z/data=!4m5!3m4!1s0x8834f70ec15a92e7:0x845976f1bc9bdd75!8m2!3d40.3944059!4d-80.0468068?hl=en-US">
                1653 McFarland Road <br />
                Pittsburgh, PA  15216
                </a>         
              </p>
              <h3>Phone</h3>
              <p>
                412-922-8222           
              </p>
              <h3>Email</h3>
              <p>
              <a href="mailto:info@hurstweiss.com">info@hurstweiss.com</a>           
              </p>
              <h3>Fax</h3>
              <p>
                412-922-1588
              </p>
            </Grid.Column>
            <Grid.Column floated="right" width={8}>
              <div className="mapouter"><div className="gmap_canvas"><iframe width={400} height={400} id="gmap_canvas" src="https://maps.google.com/maps?q=1653%20McFarland%20Road%20Pittsburgh%2C%20PA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} /><a href="https://www.embedgooglemap.net" /></div><style dangerouslySetInnerHTML={{__html: ".mapouter{text-align:right;height:400px;width:400px;}.gmap_canvas {overflow:hidden;background:none!important;height:400px;width:400px;}" }} /></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
          <a href="#">Or</a>
        </Divider>
        <div style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}>
        <Link to="/insurance">
          <Button primary size="huge">
            Request a Quote Online
            <Icon name="right arrow" />
          </Button>
        </Link>
      </div>
      </Segment>
    </Container>
  );
};

export default withLayout(ContactPage);
